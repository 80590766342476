import React from "react";
import { graphql, Link, PageProps } from "gatsby";

import { TeamPage as seo } from "../../../helpers/seo";

import SectionLayout from "../../../components/SectionLayout";

const TeamPage: React.FC<PageProps<Queries.TeamPageQuery>> = ({ data }) => (
  <SectionLayout id="about" {...seo}>
    <h1 className="text-3xl mb-8">The CLHbid.com Team</h1>
    <p>Click on a headshot to view team members stories</p>
    <ul className="w-full flex flex-wrap justify-between">
      {data.allMdx.nodes.map((node, id) => (
        <li
          key={id}
          className="w-full md:w-1/2 lg:w-full lg:max-w-xxs lg:inline-block mb-4"
        >
          <div className="border-2 border-gray-300 hover:border-clhbid-orange duration-500 md:mr-3 lg:mr-none">
            <Link to={`/about-clhbid/clhbid-team/${node.fields.slug}`}>
              <img
                className="headshot w-full"
                src={node.frontmatter.headshot}
                alt={`${name} headshot`}
              />
              <div className="p-4">
                <div className="font-bold">{node.frontmatter.name}</div>
                <div>{node.frontmatter.title}</div>
                <p className="text-clhbid-orange mb-0">Learn more »</p>
              </div>
            </Link>
          </div>
        </li>
      ))}
    </ul>
  </SectionLayout>
);

export const query = graphql`
  query TeamPage {
    allMdx(
      sort: { fields: frontmatter___order, order: ASC }
      filter: { fileAbsolutePath: { glob: "**/clhbid-team/*" } }
    ) {
      nodes {
        frontmatter {
          name
          order
          title
          headshot
        }
        fields {
          slug
        }
      }
    }
  }
`;

export default TeamPage;
